import React from 'react'
import { Box } from '@mui/material'
import Footer from '@talentinc/gatsby-theme-ecom/components/Footer/Footer'
import HeaderV2 from '@talentinc/gatsby-theme-ecom/components/HeaderV2/HeaderV2'
import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'
import { UpsellTemplate } from '@talentinc/gatsby-theme-ecom/components/NewUpsell/Template'
import { PageDataContextType } from '@talentinc/gatsby-theme-ecom/types/page'
import { upsellLayoutStyles } from '@talentinc/gatsby-theme-ecom/components/NewUpsell/styles'
import { graphql } from 'gatsby'

type Props = {
  data: PageDataContextType
  params: {
    plancode: string
  }
}

export default function Index(props: Props) {
  return (
    <Layout pageData={props.data}>
      <Box sx={upsellLayoutStyles.mainContainer}>
        <Box sx={upsellLayoutStyles.pageContainer}>
          <HeaderV2 noNavigation position="relative" />
          <UpsellTemplate />
        </Box>
        <Footer />
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query Purchase {
    brand: contentfulBrand(name: { eq: "TopResume" }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: "TopResume" } } }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: "TopResume" } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
